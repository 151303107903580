<template>
	<div>
		<v-hover v-slot="{ hover }">
			<div
				class="selectableItem rounded"
				:class="{ 'percentagePadding blue lighten-4': value }"
			>
				<div class="selectArea">
					<v-icon
						:disabled="disabled"
						v-if="!value"
						@click="
							$emit('selectClick', $event);
							$emit('input', true);
						"
						:color="hover ? 'white' : 'grey'"
					>
						{{ hover ? selectedIcon : unselectedIcon }}
					</v-icon>
					<v-icon
						v-else
						color="blue"
						@click="
							$emit('unselectClick', $event);
							$emit('input', false);
						"
					>
						{{ selectedIcon }}
					</v-icon>
				</div>

				<slot></slot>
			</div>
		</v-hover>
	</div>
</template>
<script>
export default {
	props: {
		value: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		selectedIcon: {
			type: String,
			default: "fas fa-check-circle"
		},
		unselectedIcon: {
			type: String,
			default: "far fa-circle"
		}
	}
};
</script>
<style lang="scss" scoped>
.percentagePadding {
	transition: 0.3s;
	padding: 10px;
}

.selectableItem {
	position: relative;
	transition: 0.3s;
}

.selectArea {
	position: absolute;
	z-index: 2;
	top: 10px;
	left: 10px;
}
</style>
