<template>
	<div v-if="$root.$i18n.locale === 'it'">
		<h1 class="text-h3">Termini e condizioni</h1>
		<div class="mx-5">
			<p>SGP srl - Termini e condizioni online</p>

			<p>Aggiornato 30 gennaio 2022</p>

			<p>Termini del contratto</p>

			<p>
				I presenti termini e condizioni costituiscono un contratto legalmente
				vincolante che regola il vostro utilizzo del sito ogni volta che effettuate
				un ordine con noi. Prima di utilizzare il sito, vi assumete pertanto la
				responsabilit&agrave; di leggere attentamente il presente documento.
				L&#39;utilizzo del sito SGPix da parte vostra &egrave; condizionato
				dall&#39;accettazione delle nostre norme sulla privacy,
				dall&rsquo;accettazione dell&rsquo;uso delle licenze e dall&#39;applicazione
				dei presenti termini e condizioni. Utilizzando il sito SGPix , dichiarate di
				aver accettato integralmente le norme di SGP srl. In caso contrario, non
				sarete autorizzati a utilizzarlo. Tenete presente che SGP srl si riserva il
				diritto di modificare in qualsiasi momento i presenti termini e condizioni,
				pertanto vi consigliamo di controllarli periodicamente.<br />
				Leggete attentamente le seguenti sezioni, poich&eacute; contengono i termini
				e le condizioni che regolano il vostro utilizzo del sito SGPix. Vi
				consigliamo, inoltre, di consultare le
				<router-link to="Privacy">Norme sulla privacy</router-link>

				applicabili al sito.
			</p>

			<p>
				Se avete domande, commenti o dubbi relativi al presente contratto, a
				qualsiasi altra sezione del sito o alla nostra gamma di Contenuti e servizi,
				o se avete riscontrato problemi tecnici mentre utilizzavate il sito, non
				esitate a contattarci alla mail produzione@sgpitalia.com
			</p>

			<h3 class="text-h5">1. Definizioni</h3>

			<p>
				Per &quot;Venditore&quot; si intende SGP srl, sita in via Sansovino 8,
				Milano; PIVA 03540510967 registrata alla camera di commercio di Milano REA
				MI-1682239<br />
				Per &quot;Acquirente&quot; si intende l&rsquo;azienda e la persona il cui
				nome &egrave; indicato sull&#39;Ordine.&nbsp;<br />
				Per &quot;Contratto&quot; si intende l&#39;Ordine e la Conferma d&#39;ordine
				(incluse eventuali Condizioni speciali).&nbsp;<br />
				Per &quot;Contenuti&rdquo; si intendono individuamente e collettivamente le
				immagini, le fotografie, le illustrazioni o i video forniti dal Venditore in
				conformit&agrave; con i presenti termini e condizioni.<br />
				Per &quot;Ordine&quot; si intende l&#39;ordine di Contenuti o servizi
				effettuato dall&#39;Acquirente.&nbsp;<br />
				Per &quot;Conferma d&#39;ordine&quot; si intende la Conferma scritta fornita
				dal Venditore.<br />
				Per &quot;Prezzo&quot; si intende il prezzo in vigore alla data e
				all&#39;ora in cui &egrave; stato effettuato l&#39;Ordine, comprensivo di
				eventuali offerte promozionali o sconti.&nbsp;<br />
				Per &quot;Persona&quot; si intende qualsiasi persona fisica, azienda o
				societ&agrave;.&nbsp;<br />
				Per &quot;Termini e condizioni&quot; si intendono i termini e le condizioni
				standard di utilizzo del sito illustrati nel presente documento.&nbsp;<br />
				Per &quot;Contenuto scritto&quot; si intende qualsiasi e-mail recante i nomi
				del mittente e del destinatario e qualsiasi contenuto visualizzato a schermo
				su monitor o dispositivi analoghi.
			</p>

			<h3 class="text-h5">2. Condizioni generali di vendita</h3>

			<p>
				a. Il Contratto sar&agrave; regolato dai presenti termini e condizioni
				escludendo qualsiasi altro termine, ivi inclusi eventuali termini e
				condizioni che l&#39;Acquirente potrebbe rivendicare ai sensi di eventuali
				ordini di acquisto, conferme d&#39;ordine o altri documenti.
			</p>

			<p>
				b. Eventuali variazioni dei presenti termini e condizioni sar&agrave;
				vincolante per il Venditore solo ed esclusivamente se concordata per
				iscritto tra i rappresentanti autorizzati del Venditore e
				dell&#39;Acquirente.
			</p>

			<p>
				Il sito SGPix &egrave; di propriet&agrave; di SGP srl ed &egrave; un sito
				atto a veicolare i Prodotti realizzati dai collaboratori di SGP srl ai
				clienti di SGP srl. Le preview delle immagini, dei video o di qualsiavoglia
				Prodotto veicolate tramite mail sono proposte per l&rsquo;acquisto di una
				licenza d&rsquo;uso dei prodotti. Nessuna proposta di acquisto inviata via
				mail vi vincola ad un acquisto se non dopo avere accettato i presenti
				termini e&nbsp; aver finalizzato l&rsquo;ordine.
			</p>

			<h3 class="text-h5">3. Ordini e specifiche</h3>

			<p>
				a. Tutti i Contenuti&nbsp; possono essere acquistati previa
				l&#39;accettazione dell&#39;Ordine da parte del Venditore.
			</p>

			<p>
				b. Il Venditore si riserva il diritto di rifiutare qualsiasi Ordine senza
				alcun obbligo di fornire spiegazioni. Nessun Ordine potr&agrave; essere
				considerato accettato dal Venditore finch&eacute; non venga confermato senza
				condizioni nella Conferma d&#39;ordine.
			</p>

			<h3 class="text-h5">4. Prezzo, pagamento e valute</h3>

			<p>
				a. Con la formulazione dell&rsquo;ordine si formalizza il contratto di
				compravendita. Ogni Prodotto ordinato verr&agrave; fatturato ed
				esiger&agrave; il pagamento della cifra specificata con l&rsquo;aggiuta
				dell&rsquo;aliquota IVA.
			</p>

			<p>
				b. Tutti i prezzi sono IVA esclusa. L&#39;IVA viene aggiunta al totale
				dell&#39;ordine durante il processo di pagamento, in base al tasso
				applicabile al tuo Paese. L&#39;Acquirente si assume la
				responsabilit&agrave; di pagare qualsiasi altra imposta esigibile dal
				proprio Paese.
			</p>

			<p>
				c. Il pagamento dovr&agrave; essere effettuato tramite bonifico bancario a
				seguito dell&rsquo;emissione della fattura, oppure tramite carta di
				credito/debito oppure tramite PayPal al momento in cui si effettua
				l&#39;Ordine accettato dal Venditore. In tale circostanza, sar&agrave;
				richiesto un pagamento completo e il Contratto diventer&agrave; effettivo.
			</p>

			<p>
				d. L&#39;Acquirente garantisce che tutti i dati forniti al Venditore al fine
				dell&#39;Ordine e della consegna sono corretti, che il metodo di pagamento
				scelto &egrave; di propriet&agrave; del Venditore e che sono disponibili
				fondi o risorse di credito sufficienti per coprire interamente il costo dei
				Contenuti ordinati. Il Venditore si riserva il diritto di verificare la
				validit&agrave; della carta di credito/debito o dei dati di PayPal
				dell&#39;Acquirente prima di accettare l&#39;Ordine.
			</p>

			<h3 class="text-h5">5. Consegna</h3>

			<p>
				a.I Contenuti verranno consegnati all&#39;indirizzo mail&nbsp; riportato
				sull&#39;Ordine o scaricati direttamente dal sito.
			</p>

			<p>
				b. Il Venditore si impegna a elaborare l&#39;Ordine e inviare i Contenuti
				prima possibile sulla mail dell&rsquo;Acquirente. Tuttavia, i tempi di invio
				potrebbero variare in base al tipo di connessione alla linea internet. I
				tempi di consegna non sono contemplati dal presente Contratto e il Venditore
				non potr&agrave; essere ritenuto in alcun modo responsabile per eventuali
				ritardi nella consegna dei Contenuti, indipendentemente dal motivo del
				ritardo.
			</p>

			<p>
				c.Se l&rsquo;Acquirente non dovesse ricevere le immagini dopo 24 ore
				dall&rsquo;effettuazione dell&rsquo;ordine dovr&agrave; denunciare il
				mancato ricevimento dei Contenuti e richiedere per iscritto alla mail
				<a href="mailto:produzione@sgpitalia.com">produzione@sgpitalia.com</a>
				l&rsquo;annullamento dell&rsquo;ordine. In caso di mancata comunicazione,
				l&rsquo;ordine verr&agrave; considerato valido con relativa richiesta di
				pagamento. L&rsquo;Acquirente non potr&agrave; appellarsi al mancato
				ricevimento dei&nbsp; Contenuti
			</p>

			<h3 class="text-h5">6. Limitazione di responsabilit&agrave;</h3>

			<p>
				a.Il Venditore non potr&agrave; essere ritenuto in alcun modo responsabile
				per perdite, danni, costi o spese (ivi incluse perdite di profitto)
				derivanti in modo diretto o indiretto da omissioni o ritardi
				nell&#39;adempimento degli obblighi previsti dal Contratto a causa di eventi
				o circostanze al di fuori del proprio ragionevole controllo, ivi inclusi (in
				via esemplificativa) scioperi, mobilitazioni di lavoratori, interruzioni di
				elettricit&agrave; o malfunzionamenti delle apparecchiature, provvedimenti
				governativi o caso fortuito.
			</p>

			<p>
				b. La presente limitazione di responsabilit&agrave; avr&agrave; la
				priorit&agrave; su qualsiasi clausola incoerente o contraddittoria contenuta
				nei documenti che costituiscono il presente Contratto. L&#39;Acquirente
				dovr&agrave; adottare tutte le precauzioni necessarie per garantire che il
				computer e/o il software utilizzato sia privo di virus, worm, Trojan horse e
				altri elementi dannosi. In nessun caso il Venditore potr&agrave; essere
				ritenuto responsabile nei confronti dell&#39;Acquirente o di terze parti
				associate all&#39;Acquirente per danni diretti, indiretti, speciali o
				consequenziali risultanti dall&#39;utilizzo del sito SGPix di SRG srl o di
				qualsiasi altro sito collegato, ivi incluso, in via esemplificativa, per
				perdite di profitti, interruzioni di attivit&agrave;, perdite di programmi o
				altri dati sui sistemi di gestione dei dati dell&#39;Acquirente o altro,
				anche nel caso in cui il Venditore sia stato avvertito della
				possibilit&agrave; di tali danni.
			</p>

			<h3 class="text-h5">7. Propriet&agrave; intellettuale</h3>

			<p>
				a. Il presente sito Web appartiene al Venditore ed &egrave; gestito da SGP
				srl. Se non diversamente specificato all&#39;interno del sito, SGP srl
				detiene i diritti di copyright per tutti i contenuti del sito, che includono
				quanto segue: testo, grafica, loghi, clip audio, marchi, informazioni sui
				server del software e qualsiasi altro contenuto ospitato sul sito Web. Tutti
				i diritti relativi a contenuti, servizi e informazioni sui server sono
				riservati. Qualsiasi modifica apportata ai contenuti del presente sito Web
				da terze parti costituir&agrave; una violazione dei diritti di copyright di
				SGP srl. Inoltre, il sito SGPix pu&ograve; contenere altre informazioni
				proprietarie e di copyright, i cui termini dovranno essere rispettati e
				osservati.
			</p>

			<p>
				E&rsquo; assolutamente fatto divieto di vendere, cedere in licenza, dare in
				noleggio o commercializzare in qualsiasi modo il Sito o i Contenuti di
				SGPix, senza autorizzazione da parte di SGP srl e utilizzare il Sito o i
				Contenuti di SGP srl per scopi diversi da quelli per cui sono stati creati.
				Tali usi non autorizzati possono inoltre violare le normative vigenti,
				incluse, senza alcuna limitazione, le normative sui diritti d&#39;autore e
				sui segni distintivi, sulla privacy e la personalit&agrave;, nonch&eacute; i
				regolamenti e gli statuti applicabili in materia di comunicazioni.
				L&#39;utente dichiara e garantisce di osservare tutte le leggi e le
				normative vigenti, incluse, senza alcuna limitazione, le normative relative
				a Internet, ai dati, agli account e-mail, alla privacy e alla trasmissione
				di dati tecnici esportati dal Paese di residenza dell&#39;utente. SGP srl
				non sar&agrave; responsabile del pagamento a te o a eventuali terzi in
				relazione alla fatturazione o alla riscossione di eventuali tariffe di
				licenza
			</p>

			<p>
				b. Nessun contenuto del presente sito Web potr&agrave; essere interpretato,
				per implicazione, preclusione o altro, come concessione di un diritto o una
				licenza d&#39;uso del sito o delle informazioni ivi visualizzate, tramite
				tecniche di framing o altro, fatto salvo: (a) nella misura espressamente
				consentita dai presenti termini e condizioni; ovvero (b) previa
				l&#39;autorizzazione scritta di SGP srl&nbsp; o del proprietario del marchio
				o copyright delle informazioni visualizzate sul sito.
			</p>

			<p>
				c. L&#39;Acquirente accetta che come condizione esplicita per mantenere un
				account sul sito del Venditore non dovr&agrave; utilizzare il servizio
				offerto per violare in alcun modo i diritti di propriet&agrave;
				intellettuale di terze parti. Il Venditore si riserva il diritto di
				eliminare immediatamente, senza obbligo di preavviso, l&#39;account
				dell&#39;Acquirente in caso di presunta o effettiva violazione dei diritti
				di propriet&agrave; intellettuale o di copyright di terze parti,
				nonch&eacute; di rimuovere tutti i contenuti imputabili di violazione dal
				sito Web.
			</p>

			<p>
				d. L&#39;Acquirente dichiara di essere a conoscenza del fatto che SGP srl,
				non potr&agrave; essere ritenuto responsabile per violazioni dei copyright
				di terze parti.
			</p>

			<h3 class="text-h5">8. Diritto di modifica da parte del Venditore</h3>

			<p>
				Il Venditore si riserva il diritto di apportare di tanto in tanto modifiche
				alle caratteristiche e/o alle modalit&agrave; con cui fornisce, sotto
				contratto, i propri servizi agli Acquirenti e, pertanto, di apportare
				variazioni e modifiche alle presenti Condizioni e alle proprie Norme. Gli
				Acquirenti che utilizzano regolarmente i servizi del Venditore dovranno
				controllare a intervalli periodici i collegamenti rilevanti prima di
				effettuare gli ordini.
			</p>

			<p>
				<br />
				&nbsp;
			</p>

			<p>
				Il venditore tratta i dati personali nel rispetto della propria i
				<router-link to="Privacy">informativa sulla privacy</router-link>

				secondo i principi stabiliti dal GDPR
			</p>

			<p>CONTRATTO DI LICENZA PER L&rsquo;USO DEI CONTENUTI DI SGP SRL</p>

			<p>ULTIMO AGGIORNAMENTO: gennaio 2022</p>

			<p>
				Il presente Contratto di licenza tra il Licenziatario anche detto
				l&rsquo;Acquirente e SGP srl definisce le condizioni d&#39;uso di
				fotografie, illustrazioni, file vettoriali e video clip (individualmente e
				collettivamente, i &ldquo;contenuti&rdquo;) che il Licenziatario ottiene in
				licenza da SGP srl. Scaricando contenuti da SGPix, il Licenziatario dichiara
				di accettare i termini del presente Contratto sull&rsquo;utilizzo dei
				Contenuti e del Sito.
			</p>

			<p>
				SGP srl concede la licenza di utilizzo delle immagini al licenziatario
				secondo il metodo Rights managed. I suddetti diritti hanno le seguenti
				caratteristiche:
			</p>

			<p>
				Limitati allo specifico utilizzo, supporto, periodo di tempo, tiratura di
				stampa, posizionamento, dimensione dei contenuti e territorio selezionato e
				a qualsiasi altra restrizione che accompagna i contenuti sul sito Web di SGP
				srl (o qualsiasi altro metodo di consegna dei contenuti) o in una conferma
				d&#39;ordine o fattura.Non esclusivi significa che, se non diversamente
				indicato sul sito web, in fattura, sull&#39;ordine di vendita o su un
				contratto separato, il Licenziatario non dispone di diritti esclusivi per
				l&#39;utilizzo del contenuto. SGP srl pu&ograve; quindi concedere in licenza
				gli stessi contenuti ad altri clienti.
			</p>
		</div>
	</div>
	<TermsOfUseEN v-else></TermsOfUseEN>
</template>
<script>
import TermsOfUseEN from "./TermsOfUseEN"
export default {
	components: {
		TermsOfUseEN
	}
}
</script>
